import '../styles/index.scss'

import React from "react"
import { allProductLabels, allProductTypes } from '../utils/menu_config'
import { FormattedMessage } from 'react-intl'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Icon from './iconfontCpt'

const IndexPage = ({ pageContext }) => {
  const { lang } = pageContext

  const menuKeys = {
    tools: ['mogha', 'ptk', 'zcloud-for-mogdb', 'mogdb-stack'],
    o2o: ['mtk', 'mdb', 'sca', 'mvd'],
    doc_tools: ['parameter']
  }

  const getMenus = () => {
    return <>
      {Object.keys(menuKeys).map(k => {
        return <div className="card-box" key={k}>
          <h3 className="card-title">{ allProductTypes[k] ? allProductTypes[k][lang] : k }</h3>
          <div className="card-menus">
            {menuKeys[k].map(pK => {
              const pItem = allProductLabels[pK]
              return <a rel="noreferrer" target="_blank" href={pItem.link ? `${pItem.link}&lang=${lang}` : `/${lang}/${pItem.value}`} className={`product-box ${pItem.value} ${pItem.disabled ? 'disabled' : ''}`} key={pK}>
                <div className='tool-icon'><Icon type={`d_${pItem.value}${pItem.disabled ? '_disabled': ''}`} size={22}/></div>
                <div className='info'>
                  <div className="title">{pItem.label || pItem[lang]}</div>
                  {pItem.label && <div className="desc">{pItem[lang]}</div>}
                </div>
              </a>
            })}
          </div>
        </div>
      })}
    </>
  }

  return (
    <Layout pageContext={pageContext} useSimpleHeader={true}>
      <Seo
        pageContext={pageContext}
        title="MogDB Docs"
        description="{frontmatter.summary}" />
      <article className="index-container">
        <div className="banner-container">
          <h2 className="title"><FormattedMessage id="index.title" /></h2>
          <p className="desc"><FormattedMessage id="index.desc" /></p>
        </div>
        <div className="menu-container">
          <div className="card-box">
            <h3 className="card-title">MogDB</h3>
            <p className="card-desc">{ allProductLabels.mogdb && allProductLabels.mogdb[lang] }</p>
            <div className="db-card-menus">
              <a rel="noreferrer" target="_blank" href={`/${lang}/mogdb/`} className="menu-item mogdb1">
                <div className="title"><FormattedMessage id="index.mogdbTitle1" /></div>
                <div className="desc"><FormattedMessage id="index.mogdbDesc1" /></div>
                <div className="btn"><span><FormattedMessage id="index.mogdbBtn1" /></span><Icon customClass="jump-icon" type="d_jump" size={14}/></div>
              </a>
              <a rel="noreferrer" target="_blank" href={`/${lang}/mogdb/latest/mogdb-playground`} className="menu-item mogdb2">
                <div className="title"><FormattedMessage id="index.mogdbTitle2" /></div>
                <div className="desc"><FormattedMessage id="index.mogdbDesc2" /></div>
                <div className="btn"><span><FormattedMessage id="index.mogdbBtn2" /></span><Icon customClass="jump-icon" type="d_jump" size={14}/></div>
              </a>
              <a rel="noreferrer" target="_blank" href={`/${lang}/mogdb/latest/environment-requirement`} className="menu-item mogdb3">
                <div className="title"><FormattedMessage id="index.mogdbTitle3" /></div>
                <div className="desc"><FormattedMessage id="index.mogdbDesc3" /></div>
                <div className="btn"><span><FormattedMessage id="index.mogdbBtn3" /></span><Icon customClass="jump-icon" type="d_jump" size={14}/></div>
              </a>
            </div>
          </div>
          <div className="card-box">
            <h3 className="card-title">Uqbar</h3>
            <p className="card-desc">{ allProductLabels.uqbar && allProductLabels.uqbar['long_' + lang] }</p>
            <div className="db-card-menus">
              <a rel="noreferrer" target="_blank" href={`/${lang}/uqbar`} className="menu-item uqbar1">
                <div className="title"><FormattedMessage id="index.uqbarTitle1" /></div>
                <div className="desc"><FormattedMessage id="index.uqbarDesc1" /></div>
                <div className="btn"><span><FormattedMessage id="index.uqbarBtn1" /></span><Icon customClass="jump-icon" type="d_jump" size={14}/></div>
              </a>
              <a rel="noreferrer" target="_blank" href={`/${lang}/uqbar/latest/ptk-based-installation`} className="menu-item uqbar2">
                <div className="title"><FormattedMessage id="index.uqbarTitle2" /></div>
                <div className="desc"><FormattedMessage id="index.uqbarDesc2" /></div>
                <div className="btn"><span><FormattedMessage id="index.uqbarBtn2" /></span><Icon customClass="jump-icon" type="d_jump" size={14}/></div>
              </a>
            </div>
          </div>
          {getMenus()}
        </div>
      </article>
    </Layout>
  )
}
export default IndexPage

